import { TreatmentIcon } from '@wearemotivated/design-system/components/icons/Treatment';
import { MessagesIcon } from '@wearemotivated/design-system/components/icons/Messages';
import { OrdersIcon } from '@wearemotivated/design-system/components/icons/Orders';
import { AccountIcon } from '@wearemotivated/design-system/components/icons/Account';
import { LOGIN_URL, LOGOUT_URL, ACCOUNT_URL } from '@/redesign/lib/constants';

// TODO does it come from an API?
const dashboardItems = [
  {
    name: 'My Treatments',
    url: '/my-treatments',
    IconComponent: TreatmentIcon,
  },
  {
    name: 'Messages',
    url: '/messages',
    IconComponent: MessagesIcon,
  },
  {
    name: 'Orders',
    url: '/orders',
    IconComponent: OrdersIcon,
  },
  {
    name: 'Account',
    url: '/account',
    IconComponent: AccountIcon,
  },
];

let DASHBOARD_ITEMS = dashboardItems;
try {
  DASHBOARD_ITEMS = dashboardItems.map((item) => ({
    ...item,
    url: new URL(item.url, process.env.NEXT_PUBLIC_PORTAL_HOST).toString(),
  }));
} catch (e) {
  console.error('Failed to parse dashboard items', e);
}

const DASHBOARD_ITEMS_DESKTOP = DASHBOARD_ITEMS.splice(0, 3);

export {
  LOGIN_URL,
  LOGOUT_URL,
  DASHBOARD_ITEMS,
  DASHBOARD_ITEMS_DESKTOP,
  ACCOUNT_URL,
};
