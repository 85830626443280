'use client';
import * as React from 'react';
import { Header } from '@wearemotivated/design-system/components/Header/Header';
import Icon from '@wearemotivated/design-system/components/Icon/Icon';
import { Logo } from '@wearemotivated/design-system/components/logos/Logo';
import Link from 'next/link';
import { Header as StrapiHeader } from '@strapi-schema';
import { Button } from '@wearemotivated/design-system/components/Button/Button';
import { MobileMenu } from '@wearemotivated/design-system/components/Header/MobileMenu';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@wearemotivated/design-system/components/Header/DropdownMenu';
import {
  DASHBOARD_ITEMS,
  DASHBOARD_ITEMS_DESKTOP,
  LOGIN_URL,
  LOGOUT_URL,
} from '@/app/utils/header';
import { Mixpanel } from '../utils/mixpanel';
import { clearAllSurveyState } from '@/app/evaluation/[...routeId]/utils';

interface DefaultHeaderProps {
  data: StrapiHeader;
  isAuthenticated?: boolean;
  className?: string;
}

export const DefaultHeader = ({
  data,
  isAuthenticated,
  className,
}: DefaultHeaderProps) => {
  const logoutUrl = `${LOGOUT_URL}?redirect=${
    typeof window !== 'undefined'
      ? encodeURIComponent(window.location.href)
      : ''
  }`;

  const handleLogout = () => {
    if (typeof window === 'undefined') {
      return;
    }
    clearAllSurveyState();
    Mixpanel.reset();
    window.location.href = logoutUrl;
  };

  const defaultMenuContent = (
    <>
      <nav className="my-auto flex flex-col space-y-8">
        {data.attributes?.treatments.data.map(({ attributes }, idx) => (
          <Link
            key={idx}
            href={attributes.url || ''}
            className="font-dinPro text-3xl leading-snug tracking-tight">
            {attributes.name}
          </Link>
        ))}
      </nav>
      <Button className="mb-6 w-full" asChild>
        <Link href="/evaluation/">Get Started</Link>
      </Button>
    </>
  );

  const loggedInMenuContent = (
    <>
      <div className="-mx-5 bg-grey px-5 py-8 text-black">
        <p className="mb-8 font-gtAmericaMono text-sm font-bold leading-tight">
          Select Category
        </p>

        <nav className="my-auto flex flex-col space-y-8">
          {DASHBOARD_ITEMS.map((item, idx) => (
            <a
              key={idx}
              href={item.url}
              className="flex flex-row items-center font-dinPro text-2xl leading-normal tracking-tight">
              <item.IconComponent className="mr-6" />
              {item.name}
            </a>
          ))}
        </nav>
      </div>
      <div className="py-8 text-black">
        <p className="mb-8 font-gtAmericaMono text-sm font-bold leading-tight">
          Explore more
        </p>
        <nav className="my-auto flex flex-col space-y-5">
          {data.attributes?.treatments.data.map(({ attributes }, idx) => (
            <a
              key={idx}
              href={attributes.url}
              className="font-dinPro text-2xl leading-normal tracking-tight">
              {attributes.name}
            </a>
          ))}
        </nav>
      </div>
      <Button onClick={handleLogout} className="mb-10 mt-auto w-full">
        Sign out
      </Button>
    </>
  );

  const signInHeader = (
    <a href={LOGIN_URL} className="flex flex-row items-center">
      <Icon className="h-5 w-5 shrink-0" size={21} name="user" />
      <span className="ml-2 hidden whitespace-nowrap font-dinPro text-base font-medium leading-5 tracking-tight text-black lg:inline">
        Sign In
      </span>
      <span className="sr-only lg:hidden">Sign In</span>
    </a>
  );

  const menuContent = isAuthenticated
    ? loggedInMenuContent
    : defaultMenuContent;

  return (
    <Header
      className={className}
      container
      left={
        <Link href="/" aria-label="homepage">
          <Logo className="h-5 w-auto" />
        </Link>
      }
      center={
        <nav className="hidden flex-1 items-center justify-center space-x-7 lg:flex">
          {data.attributes?.treatments.data.map(({ attributes }, index) => (
            <Link
              className="whitespace-nowrap font-dinPro text-base leading-5 tracking-tight text-black"
              href={attributes.url || ''}
              key={index}>
              {attributes.name}
            </Link>
          ))}
        </nav>
      }
      right={
        <span className="flex flex-row items-stretch">
          {isAuthenticated ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button size="auto" variant="unstyled" className="mr-0 sm:mr-6">
                  <Icon className="h-5 w-5 shrink-0" size={21} name="user" />
                  <span className="ml-2 hidden whitespace-nowrap font-dinPro text-base font-medium leading-5 tracking-tight text-black sm:ml-3 sm:block">
                    My Account
                  </span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-48" sideOffset={20}>
                {DASHBOARD_ITEMS_DESKTOP.map((item, idx) => (
                  <DropdownMenuItem key={`desktop-${idx}`}>
                    <Button
                      className="h-full w-full justify-start"
                      variant="unstyled"
                      size="auto"
                      asChild>
                      <a href={item.url}>{item.name}</a>
                    </Button>
                  </DropdownMenuItem>
                ))}
                <DropdownMenuItem>
                  <Button
                    className="h-full w-full justify-start"
                    variant="unstyled"
                    size="auto"
                    onClick={handleLogout}>
                    Sign Out
                  </Button>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            signInHeader
          )}
          <MobileMenu
            header={
              <Link href="/" aria-label="homepage" className="mr-auto">
                <Logo className="h-4 w-auto" />
              </Link>
            }
            trigger={
              <Button
                className="ml-6 flex items-center lg:hidden"
                variant="unstyled"
                size="auto">
                <Icon className="h-5 w-5" name="menu" size={14} />
                <span className="sr-only">Open Menu</span>
              </Button>
            }>
            {menuContent}
          </MobileMenu>
        </span>
      }
    />
  );
};
