import * as React from 'react';
import { getProfile, setUserIdentify } from '@/app/utils/auth';

export type UserProfileType = {
  enabled: boolean;
  customerId: string;
  customerNo: string;
  email: string;
  login: string;
  firstName: string;
  lastName: string;
  // all fields are iso date
  creationDate: string;
  lastLoginTime: string;
  lastModified: string;
  lastVisitTime: string;
  previousLoginTime: string;
  previousVisitTime: string;
  c_subscriptions: unknown[];
};

/**
 * by providing dependency array we can control when this hook is re-rendered
 */
export const useIsLoggedIn = (deps: Array<unknown> = []) => {
  const [userProfile, setUserProfile] = React.useState<UserProfileType>();

  React.useEffect(
    () => {
      const controller = new AbortController();
      const signal = controller.signal;

      (async () => {
        const profile = await getProfile(signal, false);
        if (profile) {
          setUserIdentify(profile.customerId, profile.email);
          setUserProfile(profile);
        }
      })();

      return () => {
        // because of strict mode
        controller.abort();
      };
    },
    /* eslint-disable-next-line */
    deps,
  );

  return { isLoggedIn: !!userProfile, userProfile: userProfile || null };
};
